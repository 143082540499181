import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const PricingPage = () => {
    return (
        <Layout>
            <Seo title="Pricing" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Our Pricing Plans" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Pricing" 
            />

            <section className="pricing pt-70 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="pricing-card">
                                <h5>Basic</h5>
                                <h2>$29 <sub>Per Month</sub></h2>
                                <p>$249 Billed Annually</p>
                                <p>Create your first Bootstrap website with Startup - 100% Secure.</p>
                                <ul>
                                    <li><i className="far fa-check-circle"></i> No credit card needed</li>
                                    <li><i className="far fa-check-circle"></i> Full access to all features</li>
                                    <li><i className="far fa-check-circle"></i> Demo components</li>
                                    <li className="disabled"><i className="far fa-check-circle"></i> Free Figma sources</li>
                                    <li className="disabled"><i className="far fa-check-circle"></i> 24/7 Support</li>
                                </ul>
                                <Link className="default-button default-button-2" to="/contact">Start For Free <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="pricing-card p-active">
                                <span>Save $99</span>
                                <h5>Business</h5>
                                <h2>$39 <sub>Per Month</sub></h2>
                                <p>$249 Billed Annually</p>
                                <p>Need to build a few Bootstrap sites? Choose business.</p>
                                <ul>
                                    <li><i className="far fa-check-circle"></i> No credit card needed</li>
                                    <li><i className="far fa-check-circle"></i> Full access to all features</li>
                                    <li><i className="far fa-check-circle"></i> Demo components</li>
                                    <li ><i className="far fa-check-circle"></i> Free Figma sources</li>
                                    <li className="disabled"><i className="far fa-check-circle"></i> 24/7 Support</li>
                                </ul>
                                <Link className="default-button default-button-2" to="/contact">Start For Free <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="pricing-card">
                                <span>Save $199</span>
                                <h5>Agency</h5>
                                <h2>$49 <sub>Per Month</sub></h2>
                                <p>$349 Billed Annually</p>
                                <p>Frequently generate Bootstrap websites for clients? Pick this plan.</p>
                                <ul>
                                    <li><i className="far fa-check-circle"></i> No credit card needed</li>
                                    <li><i className="far fa-check-circle"></i> Full access to all features</li>
                                    <li><i className="far fa-check-circle"></i> Demo components</li>
                                    <li ><i className="far fa-check-circle"></i> Free Figma sources</li>
                                    <li ><i className="far fa-check-circle"></i> 24/7 Support</li>
                                </ul>
                                <Link className="default-button default-button-2" to="/contact">Start For Free <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default PricingPage
